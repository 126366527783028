<template>
	<div style="height: 100vh;">
        
        <div id="container" style="height: 100vh;"></div>
		
	</div>
</template>



<script>
	
	
	export default {
		data() {
			return {
				imgUrl:'https://o.o80.cn/uploads/naoketang/img/2021/08/16174516291071426375.jpg'
			}
		},
        mounted() {
            this.initMap()
        },
        methods: {
			
            initMap() {
                var center = new window.TMap.LatLng(25.096991,104.899523);//设置中心点坐标
                //初始化地图
                var map = new window.TMap.Map("container", {
                    center: center
                });
                
                var HTML = "<div class='content'>"
                    HTML += "<img src='"+this.imgUrl+"' style='width:100%;'>"
                    HTML += "<p class='m-t-10 font-b' style='color:#665566'>兴义市第四中学</p>"
                    HTML += "<p class='font-14 ccc'>兴义市延安路36号（北门）</p>"
                    HTML += "<p class='font-14 ccc'>联系电话：0859-3815808</p>"
                    HTML += "</div>";
                    
                //设置infoWindow
                new window.TMap.InfoWindow({
                    map: map,
                    position: center,
                    content: HTML
                });
            }
            
		}
		
	}
</script>

<style>
    #container {
        width: 100%;
        height: 100%;
    }
    .content{
        width: 240px;
    }
    .content p{
        line-height: 25px;
        text-align: left;
    }
</style>
